import React, { Component } from 'react';
import Headroom from 'react-headroom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  Col,
  Row,
  css,
  styled,
  thd,
  withTheme,
  up,
} from '@smooth-ui/core-sc';
import HeaderLogo from '../../../containers/LoginPage/images/Login-Logo@2x.png';

const CustomHeadroom = styled(Headroom)`
  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }
  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  }
`;

const HeaderWrapper = styled.div`
  padding-top: 10px;
  width: 100%;
  background-color: ${thd('bodyBackground', '#F4EFEA')};
  ${up('lg', css``)};
  border-bottom: 5px ${thd('primary', '#EC1C24')} solid;
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 57px;
  justify-content: center;
  img {
    height: 35px;
    height: auto;
    width: 176px;
  }
`;

const HeaderRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 100%;

  ${up(
    'lg',
    css`
      flex-direction: column;
    `
  )}
`;

class Header extends Component {
  render() {
    return (
      <header className="site-header">
        {/* Leave the above <header /> here, necessary to contain Headroom */}
        {/* Otherwise Headroom will wrap all sibling elements */}
        <CustomHeadroom disableInlineStyles downTolerance={10}>
          <HeaderWrapper className="header-wrapper">
            <Grid>
              <Row>
                <Col>
                  <HeaderContainer>
                    <LogoContainer>
                      <Link to="/">
                        <img src={HeaderLogo} alt="Saputo Select" />
                      </Link>
                    </LogoContainer>
                    <HeaderRightContainer>
                      {this.props.children}
                    </HeaderRightContainer>
                  </HeaderContainer>
                </Col>
              </Row>
            </Grid>
          </HeaderWrapper>
        </CustomHeadroom>
      </header>
    );
  }
}

Header.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object,
};

Header.defaultProps = {
  children: undefined,
  theme: undefined,
};

export default withTheme(Header);
